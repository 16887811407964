@import "./colors";

a {
    color: #5f2eed;
    transition: all 0.3s ease-in-out;

    &:hover {
        color: darken($primary, 0.1);
    }
}
