.pt-5 {
    padding-top: 1.25rem !important;
}

.pt-6 {
    padding-top: 1.5rem !important;
}

.pt-7 {
    padding-top: 1.75rem !important;
}

.pt-8 {
    padding-top: 2rem !important;
}

.pt-9 {
    padding-top: 2.25rem !important;
}

.pt-10 {
    padding-top: 2.5rem !important;
}

.pt-11 {
    padding-top: 2.75rem !important;
}

.pt-12 {
    padding-top: 3rem !important;
}

.pt-13 {
    padding-top: 3.5rem !important;
}

.pt-14 {
    padding-top: 4rem !important;
}

.pt-15 {
    padding-top: 5rem !important;
}

.pt-16 {
    padding-top: 6rem !important;
}

.pt-17 {
    padding-top: 7rem !important;
}

.pt-18 {
    padding-top: 8rem !important;
}

.pt-19 {
    padding-top: 9rem !important;
}

.pt-20 {
    padding-top: 10rem !important;
}

.pt-21 {
    padding-top: 11rem !important;
}

.pt-22 {
    padding-top: 12rem !important;
}

.pt-23 {
    padding-top: 13rem !important;
}

.pt-24 {
    padding-top: 14rem !important;
}

.pt-25 {
    padding-top: 15rem !important;
}

.pt-26 {
    padding-top: 16rem !important;
}

.pt-27 {
    padding-top: 17rem !important;
}

.pt-28 {
    padding-top: 18rem !important;
}

.pt-29 {
    padding-top: 19rem !important;
}

.pt-30 {
    padding-top: 20rem !important;
}

.pb-5 {
    padding-bottom: 1.25rem !important;
}

.pb-6 {
    padding-bottom: 1.5rem !important;
}

.pb-7 {
    padding-bottom: 1.75rem !important;
}

.pb-8 {
    padding-bottom: 2rem !important;
}

.pb-9 {
    padding-bottom: 2.25rem !important;
}

.pb-10 {
    padding-bottom: 2.5rem !important;
}

.pb-11 {
    padding-bottom: 2.75rem !important;
}

.pb-12 {
    padding-bottom: 3rem !important;
}

.pb-13 {
    padding-bottom: 3.5rem !important;
}

.pb-14 {
    padding-bottom: 4rem !important;
}

.pb-15 {
    padding-bottom: 5rem !important;
}

.pb-16 {
    padding-bottom: 6rem !important;
}

.pb-17 {
    padding-bottom: 7rem !important;
}

.pb-18 {
    padding-bottom: 8rem !important;
}

.pb-19 {
    padding-bottom: 9rem !important;
}

.pb-20 {
    padding-bottom: 10rem !important;
}

.pb-21 {
    padding-bottom: 11rem !important;
}

.pb-22 {
    padding-bottom: 12rem !important;
}

.pb-23 {
    padding-bottom: 13rem !important;
}

.pb-24 {
    padding-bottom: 14rem !important;
}

.pb-25 {
    padding-bottom: 15rem !important;
}

.pb-26 {
    padding-bottom: 16rem !important;
}

.pb-27 {
    padding-bottom: 17rem !important;
}

.pb-28 {
    padding-bottom: 18rem !important;
}

.pb-29 {
    padding-bottom: 19rem !important;
}

.pb-30 {
    padding-bottom: 20rem !important;
}

.p-5 {
    padding: 1.25rem !important;
}

.p-6 {
    padding: 1.5rem !important;
}

.p-7 {
    padding: 1.75rem !important;
}

.p-8 {
    padding: 2rem !important;
}

.p-9 {
    padding: 2.25rem !important;
}

.p-10 {
    padding: 2.5rem !important;
}

.p-11 {
    padding: 2.75rem !important;
}

.p-12 {
    padding: 3rem !important;
}

.p-13 {
    padding: 3.5rem !important;
}

.p-14 {
    padding: 4rem !important;
}

.p-15 {
    padding: 5rem !important;
}

.p-16 {
    padding: 6rem !important;
}

.p-17 {
    padding: 7rem !important;
}

.p-18 {
    padding: 8rem !important;
}

.p-19 {
    padding: 9rem !important;
}

.p-20 {
    padding: 10rem !important;
}

.p-21 {
    padding: 11rem !important;
}

.p-22 {
    padding: 12rem !important;
}

.p-23 {
    padding: 13rem !important;
}

.p-24 {
    padding: 14rem !important;
}

.p-25 {
    padding: 15rem !important;
}

.p-26 {
    padding: 16rem !important;
}

.p-27 {
    padding: 17rem !important;
}

.p-28 {
    padding: 18rem !important;
}

.p-29 {
    padding: 19rem !important;
}

.p-30 {
    padding: 20rem !important;
}
