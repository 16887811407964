@import "./../abstracts/breakpoints";

.mt-5 {
    margin-top: 1.25rem !important;
}

.mt-6 {
    margin-top: 1.5rem !important;
}

.mt-7 {
    margin-top: 1.75rem !important;
}

.mt-8 {
    margin-top: 2rem !important;
}

.mt-9 {
    margin-top: 2.25rem !important;
}

.mt-10 {
    margin-top: 2.5rem !important;
}

.mt-11 {
    margin-top: 2.75rem !important;
}

.mt-12 {
    margin-top: 3rem !important;
}

.mt-13 {
    margin-top: 3.5rem !important;
}

.mt-14 {
    margin-top: 4rem !important;
}

.mt-15 {
    margin-top: 5rem !important;
}

.mt-16 {
    margin-top: 6rem !important;
}

.mt-17 {
    margin-top: 7rem !important;
}

.mt-18 {
    margin-top: 8rem !important;
}

.mt-19 {
    margin-top: 9rem !important;
}

.mt-20 {
    margin-top: 10rem !important;
}

.mt-21 {
    margin-top: 11rem !important;
}

.mt-22 {
    margin-top: 12rem !important;
}

.mt-23 {
    margin-top: 13rem !important;
}

.mt-24 {
    margin-top: 14rem !important;
}

.mt-25 {
    margin-top: 15rem !important;
}

.mt-26 {
    margin-top: 16rem !important;
}

.mt-27 {
    margin-top: 17rem !important;
}

.mt-28 {
    margin-top: 18rem !important;
}

.mt-29 {
    margin-top: 19rem !important;
}

.mt-30 {
    margin-top: 20rem !important;
}

.mb-5 {
    margin-bottom: 1.25rem !important;
}

.mb-6 {
    margin-bottom: 1.5rem !important;
}

.mb-7 {
    margin-bottom: 1.75rem !important;
}

.mb-8 {
    margin-bottom: 2rem !important;
}

.mb-9 {
    margin-bottom: 2.25rem !important;
}

.mb-10 {
    margin-bottom: 2.5rem !important;
}

.mb-11 {
    margin-bottom: 2.75rem !important;
}

.mb-12 {
    margin-bottom: 3rem !important;
}

.mb-13 {
    margin-bottom: 3.5rem !important;
}

.mb-14 {
    margin-bottom: 4rem !important;
}

.mb-15 {
    margin-bottom: 5rem !important;
}

.mb-16 {
    margin-bottom: 6rem !important;
}

.mb-17 {
    margin-bottom: 7rem !important;
}

.mb-18 {
    margin-bottom: 8rem !important;
}

.mb-19 {
    margin-bottom: 9rem !important;
}

.mb-20 {
    margin-bottom: 10rem !important;
}

.mb-21 {
    margin-bottom: 11rem !important;
}

.mb-22 {
    margin-bottom: 12rem !important;
}

.mb-23 {
    margin-bottom: 13rem !important;
}

.mb-24 {
    margin-bottom: 14rem !important;
}

.mb-25 {
    margin-bottom: 15rem !important;
}

.mb-26 {
    margin-bottom: 16rem !important;
}

.mb-27 {
    margin-bottom: 17rem !important;
}

.mb-28 {
    margin-bottom: 18rem !important;
}

.mb-29 {
    margin-bottom: 19rem !important;
}

.mb-30 {
    margin-bottom: 20rem !important;
}

.mb_10 {
    margin-bottom: 10px !important;
}

.mb_20 {
    margin-bottom: 20px !important;
}

.mb_30 {
    margin-bottom: 30px !important;
}

.mb_40 {
    margin-bottom: 40px !important;
}

.mt_10 {
    margin-top: 10px !important;
}

.mt_20 {
    margin-top: 20px !important;
}

.mt_30 {
    margin-top: 30px !important;
}

.mt_40 {
    margin-top: 40px !important;
}

@media all and (min-width: $grid-breakpoint-sm){
    .mt-sm-5 {
        margin-top: 1.25rem !important;
    }

    .mt-sm-6 {
        margin-top: 1.5rem !important;
    }

    .mt-sm-7 {
        margin-top: 1.75rem !important;
    }

    .mt-sm-8 {
        margin-top: 2rem !important;
    }

    .mt-sm-9 {
        margin-top: 2.25rem !important;
    }

    .mt-sm-10 {
        margin-top: 2.5rem !important;
    }

    .mt-sm-11 {
        margin-top: 2.75rem !important;
    }

    .mt-sm-12 {
        margin-top: 3rem !important;
    }

    .mt-sm-13 {
        margin-top: 3.5rem !important;
    }

    .mt-sm-14 {
        margin-top: 4rem !important;
    }

    .mt-sm-15 {
        margin-top: 5rem !important;
    }

    .mt-sm-16 {
        margin-top: 6rem !important;
    }

    .mt-sm-17 {
        margin-top: 7rem !important;
    }

    .mt-sm-18 {
        margin-top: 8rem !important;
    }

    .mt-sm-19 {
        margin-top: 9rem !important;
    }

    .mt-sm-20 {
        margin-top: 10rem !important;
    }

    .mt-sm-21 {
        margin-top: 11rem !important;
    }

    .mt-sm-22 {
        margin-top: 12rem !important;
    }

    .mt-sm-23 {
        margin-top: 13rem !important;
    }

    .mt-sm-24 {
        margin-top: 14rem !important;
    }

    .mt-sm-25 {
        margin-top: 15rem !important;
    }

    .mt-sm-26 {
        margin-top: 16rem !important;
    }

    .mt-sm-27 {
        margin-top: 17rem !important;
    }

    .mt-sm-28 {
        margin-top: 18rem !important;
    }

    .mt-sm-29 {
        margin-top: 19rem !important;
    }

    .mt-sm-30 {
        margin-top: 20rem !important;
    }

    .mb-sm-5 {
        margin-bottom: 1.25rem !important;
    }

    .mb-sm-6 {
        margin-bottom: 1.5rem !important;
    }

    .mb-sm-7 {
        margin-bottom: 1.75rem !important;
    }

    .mb-sm-8 {
        margin-bottom: 2rem !important;
    }

    .mb-sm-9 {
        margin-bottom: 2.25rem !important;
    }

    .mb-sm-10 {
        margin-bottom: 2.5rem !important;
    }

    .mb-sm-11 {
        margin-bottom: 2.75rem !important;
    }

    .mb-sm-12 {
        margin-bottom: 3rem !important;
    }

    .mb-sm-13 {
        margin-bottom: 3.5rem !important;
    }

    .mb-sm-14 {
        margin-bottom: 4rem !important;
    }

    .mb-sm-15 {
        margin-bottom: 5rem !important;
    }

    .mb-sm-16 {
        margin-bottom: 6rem !important;
    }

    .mb-sm-17 {
        margin-bottom: 7rem !important;
    }

    .mb-sm-18 {
        margin-bottom: 8rem !important;
    }

    .mb-sm-19 {
        margin-bottom: 9rem !important;
    }

    .mb-sm-20 {
        margin-bottom: 10rem !important;
    }

    .mb-sm-21 {
        margin-bottom: 11rem !important;
    }

    .mb-sm-22 {
        margin-bottom: 12rem !important;
    }

    .mb-sm-23 {
        margin-bottom: 13rem !important;
    }

    .mb-sm-24 {
        margin-bottom: 14rem !important;
    }

    .mb-sm-25 {
        margin-bottom: 15rem !important;
    }

    .mb-sm-26 {
        margin-bottom: 16rem !important;
    }

    .mb-sm-27 {
        margin-bottom: 17rem !important;
    }

    .mb-sm-28 {
        margin-bottom: 18rem !important;
    }

    .mb-sm-29 {
        margin-bottom: 19rem !important;
    }

    .mb-sm-30 {
        margin-bottom: 20rem !important;
    }
}

@media all and (min-width: $grid-breakpoint-md) {
    .mt-md-5 {
        margin-top: 1.25rem !important;
    }

    .mt-md-6 {
        margin-top: 1.5rem !important;
    }

    .mt-md-7 {
        margin-top: 1.75rem !important;
    }

    .mt-md-8 {
        margin-top: 2rem !important;
    }

    .mt-md-9 {
        margin-top: 2.25rem !important;
    }

    .mt-md-10 {
        margin-top: 2.5rem !important;
    }

    .mt-md-11 {
        margin-top: 2.75rem !important;
    }

    .mt-md-12 {
        margin-top: 3rem !important;
    }

    .mt-md-13 {
        margin-top: 3.5rem !important;
    }

    .mt-md-14 {
        margin-top: 4rem !important;
    }

    .mt-md-15 {
        margin-top: 5rem !important;
    }

    .mt-md-16 {
        margin-top: 6rem !important;
    }

    .mt-md-17 {
        margin-top: 7rem !important;
    }

    .mt-md-18 {
        margin-top: 8rem !important;
    }

    .mt-md-19 {
        margin-top: 9rem !important;
    }

    .mt-md-20 {
        margin-top: 10rem !important;
    }

    .mt-md-21 {
        margin-top: 11rem !important;
    }

    .mt-md-22 {
        margin-top: 12rem !important;
    }

    .mt-md-23 {
        margin-top: 13rem !important;
    }

    .mt-md-24 {
        margin-top: 14rem !important;
    }

    .mt-md-25 {
        margin-top: 15rem !important;
    }

    .mt-md-26 {
        margin-top: 16rem !important;
    }

    .mt-md-27 {
        margin-top: 17rem !important;
    }

    .mt-md-28 {
        margin-top: 18rem !important;
    }

    .mt-md-29 {
        margin-top: 19rem !important;
    }

    .mt-md-30 {
        margin-top: 20rem !important;
    }

    .mb-md-5 {
        margin-bottom: 1.25rem !important;
    }

    .mb-md-6 {
        margin-bottom: 1.5rem !important;
    }

    .mb-md-7 {
        margin-bottom: 1.75rem !important;
    }

    .mb-md-8 {
        margin-bottom: 2rem !important;
    }

    .mb-md-9 {
        margin-bottom: 2.25rem !important;
    }

    .mb-md-10 {
        margin-bottom: 2.5rem !important;
    }

    .mb-md-11 {
        margin-bottom: 2.75rem !important;
    }

    .mb-md-12 {
        margin-bottom: 3rem !important;
    }

    .mb-md-13 {
        margin-bottom: 3.5rem !important;
    }

    .mb-md-14 {
        margin-bottom: 4rem !important;
    }

    .mb-md-15 {
        margin-bottom: 5rem !important;
    }

    .mb-md-16 {
        margin-bottom: 6rem !important;
    }

    .mb-md-17 {
        margin-bottom: 7rem !important;
    }

    .mb-md-18 {
        margin-bottom: 8rem !important;
    }

    .mb-md-19 {
        margin-bottom: 9rem !important;
    }

    .mb-md-20 {
        margin-bottom: 10rem !important;
    }

    .mb-md-21 {
        margin-bottom: 11rem !important;
    }

    .mb-md-22 {
        margin-bottom: 12rem !important;
    }

    .mb-md-23 {
        margin-bottom: 13rem !important;
    }

    .mb-md-24 {
        margin-bottom: 14rem !important;
    }

    .mb-md-25 {
        margin-bottom: 15rem !important;
    }

    .mb-md-26 {
        margin-bottom: 16rem !important;
    }

    .mb-md-27 {
        margin-bottom: 17rem !important;
    }

    .mb-md-28 {
        margin-bottom: 18rem !important;
    }

    .mb-md-29 {
        margin-bottom: 19rem !important;
    }

    .mb-md-30 {
        margin-bottom: 20rem !important;
    }
}

@media all and (min-width: $grid-breakpoint-lg){
    .mt-lg-5 {
            margin-top: 1.25rem !important;
    }

    .mt-lg-6 {
        margin-top: 1.5rem !important;
    }

    .mt-lg-7 {
        margin-top: 1.75rem !important;
    }

    .mt-lg-8 {
        margin-top: 2rem !important;
    }

    .mt-lg-9 {
        margin-top: 2.25rem !important;
    }

    .mt-lg-10 {
        margin-top: 2.5rem !important;
    }

    .mt-lg-11 {
        margin-top: 2.75rem !important;
    }

    .mt-lg-12 {
        margin-top: 3rem !important;
    }

    .mt-lg-13 {
        margin-top: 3.5rem !important;
    }

    .mt-lg-14 {
        margin-top: 4rem !important;
    }

    .mt-lg-15 {
        margin-top: 5rem !important;
    }

    .mt-lg-16 {
        margin-top: 6rem !important;
    }

    .mt-lg-17 {
        margin-top: 7rem !important;
    }

    .mt-lg-18 {
        margin-top: 8rem !important;
    }

    .mt-lg-19 {
        margin-top: 9rem !important;
    }

    .mt-lg-20 {
        margin-top: 10rem !important;
    }

    .mt-lg-21 {
        margin-top: 11rem !important;
    }

    .mt-lg-22 {
        margin-top: 12rem !important;
    }

    .mt-lg-23 {
        margin-top: 13rem !important;
    }

    .mt-lg-24 {
        margin-top: 14rem !important;
    }

    .mt-lg-25 {
        margin-top: 15rem !important;
    }

    .mt-lg-26 {
        margin-top: 16rem !important;
    }

    .mt-lg-27 {
        margin-top: 17rem !important;
    }

    .mt-lg-28 {
        margin-top: 18rem !important;
    }

    .mt-lg-29 {
        margin-top: 19rem !important;
    }

    .mt-lg-30 {
        margin-top: 20rem !important;
    }

    .mb-lg-5 {
        margin-bottom: 1.25rem !important;
    }

    .mb-lg-6 {
        margin-bottom: 1.5rem !important;
    }

    .mb-lg-7 {
        margin-bottom: 1.75rem !important;
    }

    .mb-lg-8 {
        margin-bottom: 2rem !important;
    }

    .mb-lg-9 {
        margin-bottom: 2.25rem !important;
    }

    .mb-lg-10 {
        margin-bottom: 2.5rem !important;
    }

    .mb-lg-11 {
        margin-bottom: 2.75rem !important;
    }

    .mb-lg-12 {
        margin-bottom: 3rem !important;
    }

    .mb-lg-13 {
        margin-bottom: 3.5rem !important;
    }

    .mb-lg-14 {
        margin-bottom: 4rem !important;
    }

    .mb-lg-15 {
        margin-bottom: 5rem !important;
    }

    .mb-lg-16 {
        margin-bottom: 6rem !important;
    }

    .mb-lg-17 {
        margin-bottom: 7rem !important;
    }

    .mb-lg-18 {
        margin-bottom: 8rem !important;
    }

    .mb-lg-19 {
        margin-bottom: 9rem !important;
    }

    .mb-lg-20 {
        margin-bottom: 10rem !important;
    }

    .mb-lg-21 {
        margin-bottom: 11rem !important;
    }

    .mb-lg-22 {
        margin-bottom: 12rem !important;
    }

    .mb-lg-23 {
        margin-bottom: 13rem !important;
    }

    .mb-lg-24 {
        margin-bottom: 14rem !important;
    }

    .mb-lg-25 {
        margin-bottom: 15rem !important;
    }

    .mb-lg-26 {
        margin-bottom: 16rem !important;
    }

    .mb-lg-27 {
        margin-bottom: 17rem !important;
    }

    .mb-lg-28 {
        margin-bottom: 18rem !important;
    }

    .mb-lg-29 {
        margin-bottom: 19rem !important;
    }

    .mb-lg-30 {
        margin-bottom: 20rem !important;
    }
}

@media all and (min-width: $grid-breakpoint-lg){
    .mt-xl-5 {
        margin-top: 1.25rem !important;
    }

    .mt-xl-6 {
        margin-top: 1.5rem !important;
    }

    .mt-xl-7 {
        margin-top: 1.75rem !important;
    }

    .mt-xl-8 {
        margin-top: 2rem !important;
    }

    .mt-xl-9 {
        margin-top: 2.25rem !important;
    }

    .mt-xl-10 {
        margin-top: 2.5rem !important;
    }

    .mt-xl-11 {
        margin-top: 2.75rem !important;
    }

    .mt-xl-12 {
        margin-top: 3rem !important;
    }

    .mt-xl-13 {
        margin-top: 3.5rem !important;
    }

    .mt-xl-14 {
        margin-top: 4rem !important;
    }

    .mt-xl-15 {
        margin-top: 5rem !important;
    }

    .mt-xl-16 {
        margin-top: 6rem !important;
    }

    .mt-xl-17 {
        margin-top: 7rem !important;
    }

    .mt-xl-18 {
        margin-top: 8rem !important;
    }

    .mt-xl-19 {
        margin-top: 9rem !important;
    }

    .mt-xl-20 {
        margin-top: 10rem !important;
    }

    .mt-xl-21 {
        margin-top: 11rem !important;
    }

    .mt-xl-22 {
        margin-top: 12rem !important;
    }

    .mt-xl-23 {
        margin-top: 13rem !important;
    }

    .mt-xl-24 {
        margin-top: 14rem !important;
    }

    .mt-xl-25 {
        margin-top: 15rem !important;
    }

    .mt-xl-26 {
        margin-top: 16rem !important;
    }

    .mt-xl-27 {
        margin-top: 17rem !important;
    }

    .mt-xl-28 {
        margin-top: 18rem !important;
    }

    .mt-xl-29 {
        margin-top: 19rem !important;
    }

    .mt-xl-30 {
        margin-top: 20rem !important;
    }

    .mb-xl-5 {
        margin-bottom: 1.25rem !important;
    }

    .mb-xl-6 {
        margin-bottom: 1.5rem !important;
    }

    .mb-xl-7 {
        margin-bottom: 1.75rem !important;
    }

    .mb-xl-8 {
        margin-bottom: 2rem !important;
    }

    .mb-xl-9 {
        margin-bottom: 2.25rem !important;
    }

    .mb-xl-10 {
        margin-bottom: 2.5rem !important;
    }

    .mb-xl-11 {
        margin-bottom: 2.75rem !important;
    }

    .mb-xl-12 {
        margin-bottom: 3rem !important;
    }

    .mb-xl-13 {
        margin-bottom: 3.5rem !important;
    }

    .mb-xl-14 {
        margin-bottom: 4rem !important;
    }

    .mb-xl-15 {
        margin-bottom: 5rem !important;
    }

    .mb-xl-16 {
        margin-bottom: 6rem !important;
    }

    .mb-xl-17 {
        margin-bottom: 7rem !important;
    }

    .mb-xl-18 {
        margin-bottom: 8rem !important;
    }

    .mb-xl-19 {
        margin-bottom: 9rem !important;
    }

    .mb-xl-20 {
        margin-bottom: 10rem !important;
    }

    .mb-xl-21 {
        margin-bottom: 11rem !important;
    }

    .mb-xl-22 {
        margin-bottom: 12rem !important;
    }

    .mb-xl-23 {
        margin-bottom: 13rem !important;
    }

    .mb-xl-24 {
        margin-bottom: 14rem !important;
    }

    .mb-xl-25 {
        margin-bottom: 15rem !important;
    }

    .mb-xl-26 {
        margin-bottom: 16rem !important;
    }

    .mb-xl-27 {
        margin-bottom: 17rem !important;
    }

    .mb-xl-28 {
        margin-bottom: 18rem !important;
    }

    .mb-xl-29 {
        margin-bottom: 19rem !important;
    }

    .mb-xl-30 {
        margin-bottom: 20rem !important;
    }
}
