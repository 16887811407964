@import "../abstracts/breakpoints";

.erp_action_content h3 {
    line-height: 3.2rem;
}

@media only screen and (max-width: $grid-breakpoint-sm)  {
    .seo_banner_content p {
        font-size: 15px;
    }
}

