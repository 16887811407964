.topbar {
    background: #5e2ced;
    padding: 8px;
    display: flex;
    justify-content: center;
    width: 100%;

    .badge-pill {
        padding: 10px;
        border-radius: 999px;
        margin-left: 10px;
    }

    .badge-primary {
        color: #282835;
        background-color: #fff;
    }
}

.topbar-text {
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    text-align: center;
}

#topbar-sale {
    margin-left:0.5em;
}

.topbar-countdown {
    justify-content: center;
}

